.container {
  display: flex;
  height: 90%;
  border-radius: 7px;
  background: var(--color-white);
}

.list {
  width: 40%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  color: var(--color-dark-blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  height: 40px;
  padding: 20px 40px;
  border-bottom: 1px solid var(--color-light-gray);
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.create {
  display: flex;
  align-items: center;

  cursor: pointer;
}
.guide {
  z-index: 100;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0 10px;
}

@media screen and (max-width: 768px){
  .container {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .list{
    width: 100%;
  }
}