.container {
    position: relative;
    display: flex;
    height: 100svh;
    justify-content: center;
    align-items: flex-start;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    margin-top: 25vh;
}

.banner {
    width: 40%;
    height: 100%;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 530px;
    margin-bottom: 20px;

    color: var(--color-dark-orange);
    font-family: Montserrat, serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.input {
    height: 50px;
    width: 530px;
    padding: 5px 10px;
    border-radius: 7px;
    border: 1px solid var(--color-white-gray);
    background: var(--color-white-gray);
}

.warning {
    height: 20px;
    font-size: 13px;
    color: red;
}

.wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 30px;

    height: 50px;
    width: 530px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 160px;

    cursor: pointer;
    color: white;
    background-color: var(--color-dark-orange);
    border-radius: 7px;
}

.forgot {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    color: var(--color-light-blue-gray);
    font-size: 14px;
    text-decoration-line: underline;
}

.disclaimer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 530px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 10px 0;
    color: var(--color-light-blue-gray);
}

.checkbox {
    cursor: pointer;
}

.terms {
    cursor: pointer;
    text-decoration: underline;
}

.terms:hover {
    color: var(--color-dark-orange);
}

.beta {
    color: var(--color-light-blue-gray);
    transform: translate(-100%, 0);
    font-size: 14px;
    position: absolute;
    top: 95%;
    left: 99%;
    width: 380px;
}

.leftSide {
    position: relative;
    width: 60%;
    height: 100%;
}

.spinner {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    display: inline-block;
    width: 40px;
    height: 40px;
    border: 3px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px)  {
    .container {
        overflow-y: hidden;
    }

    .banner {
        display: none;
    }

    .logo {
        width: 100%;
    }

    .panel {
        width: 100%;
    }

    .leftSide {
        width: 100%;
    }

    .beta {
        width: 100%;
        left: 50%;
        top: 90%;
        transform: translate(-50%, 0);
        text-align: center;
    }

    .input {
        width: 90%;
    }

    .wrapper {
        width: 100%;
        justify-content: center;
    }

    .disclaimer {
        width: 90%;
    }
}