.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray);

  width: 450px;
  height: 250px;
  z-index: 101;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

  position: fixed;
  top: 78%;
  left: 83%;
  transform: translate(-50%, -50%);
}

.title {
  padding: 10px 30px;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: var(--color-dark-blue);
}

.buttonbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.button {
  text-align: center;
  padding: 10px;
  min-width: 100px;
  border: 1px solid var(--color-gray);
  border-radius: 5px;
  cursor: pointer;
}

.background {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 99;
  height: 100%;
  width: 100%;
  opacity: 70%;

  background-color: var(--color-dark-gray);
}

.orange {
  background-color: var(--color-orange);
  color: var(--color-white);
  border: none;
}