.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.number {
  width: 170px;
  height: 50px;
  padding: 0 16px;
  text-align: right;
  font-size: 16px;

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

.success {
  height: 24px;
  padding: 0 16px;

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: green;
}

@media screen and (max-width: 768px) {
  .container {
    align-items: center;
  }
}