.container {
  padding: 10px;

  font-family: Inter, serif;
  font-size: 18px;
  color: var(--color-dark-blue);
  overflow: auto;
  overflow-x: hidden;
}

.block {
  border: 1px solid var(--color-light-blue);
  padding: 20px 30px;
  border-radius: 5px;
  margin-top: 10px;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 24px;
}

.left {
  color: var(--color-dark-blue);
}

.right {
  color: var(--color-light-blue-gray);
}

.content {
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .container {
    overflow-y: auto;
    z-index: 1;
  }

  ::-webkit-scrollbar{
    width: 0;
  }
}