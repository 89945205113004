.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  transform: translate(-50%, -50%);

  max-width: 600px;
  min-height: 300px;
  padding: 20px;
  border-radius: 5px;
  z-index: 3;

  border: 1px solid #D4D6E5;
  background-color: white;
}

.close {
  position: fixed;
  top: 6%;
  left: 97%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  padding: 10px;

  font-size: 21px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--color-dark-blue);
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: var(--color-light-blue-gray);
}

.bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  gap: 40px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 110px;
  padding: 15px;
  cursor: pointer;

  color: var(--color-dark-blue);
  border-radius: 5px;
  border: 1px solid var(--color-light-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 20px;
  text-decoration-line: none;
}

.auth {
  top: 50%;
  left: 30%;
}

.main {
  top: 50%;
  left: 50%;
}

.orange {
  background-color: var(--color-dark-orange);
  color: var(--color-white);
}