.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  margin: 10px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 35px;
  padding: 20px;
}

.text {
  height: 100%;
  width: 100%;
  padding: 0 10px;

  color: var(--color-dark-blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 7px;
  border: 1px solid var(--color-light-gray);
  background: #FAFAFA;
}

.send {
  height: 45px;
  cursor: pointer;
}

.header {
  position: relative;
  padding: 10px;

  border: solid var(--color-light-gray) 1px;
}

.name {
  text-align: center;
}

.close {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 5%;
}

@media screen and (max-width <= 768px) {
  .container {
    min-height: 100%;
  }
}