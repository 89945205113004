.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-dark-blue);
}

.popup {
  position: absolute;
  top: 55px;
  z-index: 100;
  padding: 20px;

  background: var(--color-white);
  box-shadow: 3px 3px 13px -5px var(--color-light-blue-gray);
  border-radius: 5px;
}

.item {
  padding: 0 20px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #EEF1F4;
}

.button:hover {
  background-color: rgba(237, 120, 72, 0.3);;
}

.input {
  width: 170px;
  height: 50px;
  padding: 0 16px;
  text-align: right;
  font-size: 16px;

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

.button {
  padding: 10px;
  border-radius: 4px;
  background: var(--color-white);
  border: 1px solid #EEF1F4;
  margin-top: 4px;
  cursor: pointer;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-bottom: 20px;
}

.separator {
  text-align: center;
  padding: 10px;
  color: #EEF1F4;
}

.drop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 50px;
  text-align: end;

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

.range {
  display: flex;
  justify-content: space-around;
  width: 200px;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .popup {
    left: -27%;
  }
}