.container {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.card {
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  padding: 20px;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  color: var(--color-dark-blue);
}

.title {
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
}

.params {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  padding: 5px 10px;
}

.value {
  padding: 5px 10px;
}

.dots {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 13%;
  left: 95%;
}

.current {
  background-color: var(--color-dark-orange);
  color: white;
}