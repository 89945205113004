.container {
  width: 100%;
  height: 10%;
}

.panel {
  position: relative;
  width: 100%;
  height: 50px;
  animation: all 2s ease-in-out;
  margin: 10px 20px;
}

.roll {
  position: absolute;
  cursor: pointer;
  top: 10%;
  left: 75%;
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}

.close {
  position: absolute;
  cursor: pointer;
  top: 120%;
  left: 5%;
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}

.disappear {
  opacity: 0;
}

@media screen and (max-width: 768px){
  .close {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 85%;
    transition: none;
  }

  .roll {
    transform: translate(-50%, -50%);
    transition: none;
    top: 50%;
    left: 80%;
  }
}