.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;

  padding: 5px 20px;
  width: 160px;
  min-height: 40px;

  color: var(--color-dark-blue);
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

.other {
  width: 170px;
  height: 50px;
  padding: 0 16px;
  text-align: right;
  font-size: 16px;

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

.active {
  color: white;
  background-color: var(--color-dark-orange);
}

.inactive {
  color: var(--color-light-blue-gray);
  background: var(--color-white-gray);
}

.success {
  height: 24px;
  padding: 0 16px;

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: green;
}

@media screen and (max-width: 768px) {
  .buttons {
    justify-content: center;
  }
}