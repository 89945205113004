.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    border: 1px solid #ff9500;
    border-radius: 5px;
    background-color: white;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 50%;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: var(--color-light-blue-gray);

    padding: 10px;
    border-radius: 5px;
    border: 1px solid #AEAEAE;
}

.image {
    width: 80px;
    height: 80px;
}

@media screen and (max-width: 768px) {
    .container {
        width: 90%;
        flex-direction: column;
        gap: 10px;
        padding: 10px 5px;
        margin: 0 auto;
    }

    .input {
        width: 80%
    }
}