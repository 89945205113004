.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  margin: 20px auto;
  width: 500px;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  color: var(--color-dark-blue);
  border-radius: 7px;
  background-color: var(--color-white);
  border: 1px solid var(--color-light-blue);

  cursor: pointer;
}

.dots {
  width: 40px;
  text-align: center;
  padding: 15px 0 2px 0;
}

.page.active {
  background-color: #E1E6F6;
}

.page:hover {
  color: var(--color-white);
  background-color: var(--color-dark-orange);

  path {
    stroke: var(--color-white);
  }
}