.panel {
  display: grid;
}

.title {
  padding: 10px 0;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-dark-blue);
}

.table {
  display: grid;
  border-left: 1px solid var(--color-white-gray);
  border-bottom: 1px solid var(--color-white-gray);
}

.label {
  border-top: 1px solid var(--color-white-gray);
  border-right: 1px solid var(--color-white-gray);
  padding: 5px;

  font-weight: 500;
  color: var(--color-dark-blue);
  text-align: center;
}

.row {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.field {
  padding: 5px;

  border-top: 1px solid var(--color-white-gray);
  border-right: 1px solid var(--color-white-gray);
  text-align: center;
  color: var(--color-light-blue-gray);
}