.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  text-transform: uppercase;
  margin: 0 auto;

  color: var(--color-dark-blue);
}

.wrapper {
  padding: 20px 0;
  max-height: 80vh;
  overflow-y: auto;
}

.types {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
}

.crane {
  position: relative;
}

.crane .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: opacity 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-align: center;
  color: var(--color-gray);

  font-size: 30px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.02em;
}

.crane:hover .overlay {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
}

.block:not(:hover) .overlay {
  opacity: 0;
}

.img {
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: 1899.99px) {
  .crane {
    height: 210px;
  }
}

@media (min-width: 1900px) {
  .crane {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .crane {
    height: 210px;
  }

  .title {
    line-height: 40px;
    padding: 0 10px;
    font-size: 24px;
  }

  .types {
    gap: 15px;
  }

  .crane .overlay {
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    height: 210px
  }
}