.container {
  display: flex;
  flex-direction: column;
  align-items:  flex-start;
  padding: 20px;
  gap: 15px;
}

.right {
  align-items: flex-end;
}

.message {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;
  width: 80%;

  color: var(--color-dark-blue);
  border-radius: 15px 15px 15px 4px;
  background: var(--color-white-gray);
}

.owner {
  color: #E1E6F6;
  border-radius: 15px 15px 4px 15px;
  background: var(--color-gray-blue);
}

.block {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: 700;
  margin-bottom: 10px;
}