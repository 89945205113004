.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.content {
  padding: 20px 40px;
  background-color: white;
  height: 80vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 10px;

  height: 65px;
  padding: 0 20px;

  color: var(--color-dark-blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 102.023%;

  background-color: white;
  border-bottom: 1px solid #EEF1F4;
  box-shadow: 10px 0 37px #EEF3F5;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  gap: 10px;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;

  text-transform: uppercase;
  border-bottom: 3px solid white;
  color: var(--color-dark-blue);
}

.item:hover {
  color: grey;
}

.active {
  color: var(--color-orange);
  border-bottom: 3px solid var(--color-orange);
}

.content {
  padding: 20px 40px;
}

.scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 80vh;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f9f9fd;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--color-orange);
}

.label {
  width: 70%;
  margin: 20px 0 10px;
  padding: 0 20px;

  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 102.02%;

  color: var(--color-light-blue-gray);
}

.title {
  margin: 15px;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: start;

  color: var(--color-dark-blue);
}

.section {
  display: flex;
  justify-content: space-between;
}

.param {
  display: flex;
  flex-direction: column;
}

.string {
  width: 300px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 20px;

  text-decoration-line: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  width: 125px;
  padding: 20px;
  cursor: pointer;

  color: var(--color-dark-blue);
  border-radius: 4px;
  border: 1px solid var(--color-light-blue);

  a {
    color: var(--color-dark-blue)
  }
}

.buttonContainer:hover {
  color: var(--color-white);
  background-color: var(--color-dark-orange);

  a {
    color: var(--color-white)
  }
}


.buttonContainer:hover {
  color: var(--color-white);
  background-color: var(--color-dark-orange);

  path {
    stroke: var(--color-white);
  }
}

.viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 100%;
  height: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  height: 45px;
}

.save {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  padding: 5px;
}

.popup {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  height: 40%;
  width: 85%;

  background-color: var(--color-white);
  z-index: 4;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.blackout {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 2;
  z-index: 3;
}

.close {
  position: absolute;
  top: 5%;
  left: 90%;
}

.guide {
  z-index: 100;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px){
  .content {
    width: 100%;
    padding: 0;
  }

  .scroll {
    height: 90%;
  }
}