.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.side {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;

  min-width: 300px;

  transition: min-width 1s ease;
  transition-delay: 0.5s;
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
  width: 100%;

  background-color: var(--color-white-gray);
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 61px;
  text-transform: uppercase;
  padding: 40px 0 0 70px;

  color: var(--color-dark-blue);
}

.content {
  width: 100%;
}

.close {
  min-width: 90px;
}

.guide {
  z-index: 100;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0 10px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    height: 100dvh
  }

  .panel {
    padding: 0;
    min-height: 92dvh;
  }

  .side {
    height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-white);
    z-index: 15;
    transition: none;
  }

  .close {
    position: static;
    height: 80px;
    width: 100%;
    transition: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
}