.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 10px;
  color: var(--color-light-blue-gray);
}

.popup {
  position: absolute;
  top: 55px;
  width: 200px;
  max-height: 170px;
  z-index: 100;
  overflow-y: auto;

  background-color: var(--color-white-gray);
  border: 1px solid var(--color-orange);
  border-radius: 5px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;

  padding: 5px 20px;
  min-height: 40px;
  color: var(--color-dark-blue);
  text-align: initial;
  z-index: 15;
}

.item:hover {
  background-color: rgba(237, 120, 72, 0.3);;
}

.input {
  width: 95%;
  height: 50px;
  padding: 0 16px;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark-blue);

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

::-webkit-input-placeholder {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;

  color: var(--color-light-blue-gray);
}

@media screen and (max-width: 768px) {
  .container {
    align-items: center;
  }
}