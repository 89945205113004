.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  margin-bottom: 20px;
  color: var(--color-dark-blue);
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 16px;
  width: 170px;
  min-height: 40px;
  text-align: left;

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

.placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;

  color: var(--color-light-blue-gray);
}

.other {
  width: 170px;
  height: 50px;
  padding: 0 16px;
  text-align: right;
  font-size: 16px;

  border: 1px solid grey;
  border-radius: 10px;
}

.success {
  height: 24px;
  padding: 0 16px;

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: green;
}

.popup {
  position: absolute;
  top: 55px;
  width: 200px;
  max-height: 170px;
  z-index: 100;
  overflow-y: auto;

  background-color: var(--color-white-gray);
  border: 1px solid var(--color-orange);
  border-radius: 5px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;

  padding: 5px 20px;
  min-height: 40px;
  color: var(--color-dark-blue);
  text-align: initial;
  z-index: 15;
}

.item:hover {
  background-color: rgba(237, 120, 72, 0.3);;
}

.input {
  width: 170px;
  height: 50px;
  padding: 0 16px;
  text-align: right;
  font-size: 16px;

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

@media screen and (max-width: 768px) {
  .container {
    align-items: center;
  }
}