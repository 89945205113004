.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  width: 50%;
  margin: 20px 0;

  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  text-align: start;

  color: var(--color-dark-blue);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.success {
  height: 24px;
  padding: 0 16px;
  margin: 10px 20px;

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: green;
}