.container {
  overflow: auto;
  overflow-x: hidden;
}

.block {
  gap: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;

  background-color: var(--color-white-gray);
  border-radius: 5px;
}

.title {
  padding: 0 0 30px 0;

  font-family: Inter, serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--color-dark-blue);
}

.content {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--color-dark-blue);
}

.date {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--color-light-blue-gray);
}

@media screen and (max-width: 768px) {
  .container {
    overflow-y: auto;
    z-index: 1;
  }
}