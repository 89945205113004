.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 10px;
  color: var(--color-light-blue-gray);
}

.input {
  width: 95%;
  height: 50px;
  padding: 0 16px;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark-blue);

  border: none;
  border-radius: 5px;
  background-color: var(--color-white-gray);
}

::-webkit-input-placeholder {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;

  color: var(--color-light-blue-gray);
}

@media screen and (max-width: 768px) {
  .container {
    align-items: center;
  }
}