.container {
  display: flex;
  align-items: center;
  gap: 30px;

  cursor: pointer;
  padding: 15px 25px;
  border-bottom: 1px solid var(--color-light-gray);
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  color: var(--color-dark-blue);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.name {
  font-weight: 500;
}

.title {
  font-weight: 400;
  margin-bottom: 10px;
}

.active {
  background-color: #E1E6F6;
}
