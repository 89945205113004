.container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 60%;
}

.banner {
    width: 40%;
    height: 100%;
}

.input {
    height: 50px;
    width: 530px;
    padding: 5px 10px;
    border-radius: 7px;
    border: 1px solid #EDEFF6;
    background: var(--color-white-gray);
}

.warning {
    height: 20px;
    font-size: 13px;
    color: red;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 160px;

    cursor: pointer;
    color: white;
    background-color: #F57C4A;
    border-radius: 7px;
}
