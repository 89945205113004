.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 6px;
  gap: 10px;

  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: 1000;

  opacity: 0;

  min-width: 300px;
  max-width: 400px;
  word-wrap: normal;
  min-height: 70px;
  padding: 8px 12px;
}

.container.show {
  opacity: 1;
  animation: fade-in 0.2s ease-in-out forwards;
}

.container.hide {
  opacity: 0;
  animation: fade-out 0.2s ease-in-out forwards;
}

.error {
  background-color: #FBE7EB;
  color: #FD4264;
}

.warning {
  background-color: #fff8cc;
  color: #ff9a03;
}

.success {
  background-color: #D3EFEC;
  color: #226457;
}

.icon {
  display: flex;
  align-items: center;
}

.message {
  display: flex;
  align-items: center;

  text-align: start;
}

.close {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    transform: translate(-50%, -50%);
    top: 8%;
    left: 50%;
    width: 90%;
    max-height: 40px;
  }
}