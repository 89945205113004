.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width:  160px;
    height: 40px;
    color: var(--color-dark-blue);
    padding-left: 10px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 70px;

    opacity: 1;
    transition: opacity 0.5s;
    transition-delay: 1s;
}

.item:hover {
    color: var(--color-dark-orange);
}

.active {
    color: var(--color-dark-orange);
    border-radius: 7px;
    background: rgba(245, 124, 74, 0.15);
}