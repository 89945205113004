.container {
  width: 700px;
  background: var(--color-white);
  margin: 0 auto;
  height: 100%;
  max-height: 930px;
  padding: 30px 30px;
  border-radius: 7px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  margin-bottom: 10px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  text-transform: uppercase;

  color: var(--color-dark-blue);
}

.premium {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: var(--color-orange);
}

.button {
  background: var(--color-dark-orange);
  width: 100px;
  border-radius: 7px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}

.scroll {
  height: 85%;
  overflow-y: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.block {
  margin-top: 10px;
  margin-bottom: 15px;
}

.input {
  margin-top: 20px;
}

.warning {
  height: 10px;
  font-size: 13px;
  color: red;
  margin: 10px 0;
}

.checkbox {
  display: flex;
  flex-direction: row;
  gap: 6px;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 10px 0;
  max-width: 400px;
  cursor: pointer;
  color: var(--color-light-blue-gray);
}

.checkboxInput {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.guide {
  z-index: 100;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    background: var(--color-white);
    margin: 0 0;
    height: 100%;
    max-height: 90vh;
    padding: 0;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;

    margin-bottom: 0;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
    text-transform: uppercase;

    color: var(--color-dark-blue);
  }

  .premium {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: var(--color-orange);
  }

  .input {
    width: 100%;
  }

  .scroll {
    min-height: 1000px;
    overflow-y: visible;
    padding: 0;
    align-items: center;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    gap: 30px
  }
}