@font-face {
  font-family: 'Roboto Thin';
  src: local('Roboto Thin'), url('fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Regular'), url('fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), url('fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope Regular'), url('fonts/Manrope/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), url('fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), url('fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a, u {
  text-decoration: none;
}

:root {
  --color-light-gray: #D6D6D6;
  --color-light-blue-gray: #ACB1CD;
  --color-gray-blue: #707CB1;
  --color-dark-blue: #3D487C;
  --color-light-blue: #E4ECF3;
  --color-gray: #DEDEDE;
  --color-white-gray: #F3F4F8;
  --color-white: #FFFFFF;
  --color-dark-gray: #333333;
  --color-dark-orange: #F57C4A;
  --color-orange: #DF8628;
}