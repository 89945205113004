.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  width: 100%;
  height: 35px;
}

.text {
  height: 100%;
  width: 100%;
  padding: 0 10px;

  color: var(--color-dark-blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 7px;
  border: 1px solid var(--color-light-gray);
  background: #FAFAFA;
}

.send {
  height: 45px;
  cursor: pointer;
}