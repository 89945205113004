.container {
  display: flex;
  flex-direction: column;
  position: fixed;

  cursor: pointer;
  width: 300px;

  left: 0;
  bottom: -200px;
  transition: transform 0.5s ease, width 1s ease;
  transition-delay: 0s, 0.5s;
  z-index: 2;
  background: white;
}

.open {
  transform: translateY(-200px);
}

.close {
  transform: translateY(0px);
}

.header {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  width: 60%;
}

.menu {
  margin-left: 35px;
  margin-top: 20px;
}

.icon {
  margin-left: 25px;
}

.item {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--color-dark-blue) ;
  padding: 8px 0;
}

.exit {
  cursor: pointer;
  margin: 16px 0;
  color: darkgray;
}

.circle {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 62%;
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.fadeIn {
  transition: opacity 1s;
  transition-delay: 1s;
  opacity: 1;
  display: flex;
}

.fadeOut {
  transition: top 1s;
  opacity: 0;
  position: fixed;
  top: -9999%;
}

.menuClose {
  padding-left: 0;
}

.closeIcon {
  padding: 10px 0;
  border-top: 1px solid #EEF1F4;
  border-bottom: 1px solid #EEF1F4;
}

.line {
  height: 1px;
  background: #EEF1F4;
  width: 300px;
  transition: width 1s ease;
  transition-delay: 0.5s;
}

.lineClose {
  width: 90px
}

.containerClose {
  width: 90px
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    transition: transform 0.5s ease, width 1s ease,opacity 0.5s;
    transition-delay: 0s, 0.5s, 1s;
  }

  .containerClose {
    position: fixed;
    opacity: 0;
    top: -99999%;
    left: -99999%;
  }

  .name {
    width: 100%;
    justify-content: flex-end;
    gap: 45%;
    padding: 0 5%;
  }

  .line {
    width: 100%;
  }
}