.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  padding: 20px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.info {
  display: block;
  width: 300px;

  color: #AEAEAE;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
