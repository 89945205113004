.container {
  height: 95%;
  border-radius: 5px;
  background-color: var(--color-white);
  padding: 20px;
  outline: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.group {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: start;
  color: var(--color-dark-blue);
}

.item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  max-width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
}

.title {
  padding: 10px 20px;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
  text-align: left;
  color: var(--color-dark-blue);
}

.groupTitle {
  border-top: 1px solid var(--color-white-gray);
  border-bottom: 1px solid var(--color-white-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 25px;

  font-weight: 300;
  font-size: 24px;
  line-height: 102.02%;
  color: var(--color-light-blue-gray);
}

.footer {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.total {
  display: flex;
  flex-direction: column;

  font-size: 32px;
  padding: 0 15px;
  color: var(--color-dark-blue);
}

.more {
  font-size: 18px;
  cursor: pointer;
}

.calculate {
  padding: 20px 40px;

  cursor: pointer;
  border-radius: 5px;
  background-color: var(--color-orange);
  color: var(--color-white);
}

.popup {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  z-index: 100;
  overflow-y: auto;
  padding: 10px;

  display: grid;
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.background {
  background-color: var(--color-dark-gray);
  opacity: 0.3;
  z-index: 99;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

.param {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.header {
  text-align: center;
  border: 1px solid var(--color-light-blue-gray);
  padding: 10px;
}

.cell {
  border: 1px solid var(--color-light-blue-gray);
  padding: 10px;
}

.popupFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}

.close {
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: var(--color-white);
  background-color: var(--color-orange);
}

.table {
  overflow-y: auto;
}