.container {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-right: 1px solid var(--color-light-gray);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--color-dark-blue);

  height: 40px;
  padding: 20px;
  border-bottom: 1px solid var(--color-light-gray);
}

.list {
  overflow: auto;
}

@media screen and (max-width: 768px){
  .container {
    width: 100%;
  }
}