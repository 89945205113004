.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 80px;
  height: 70%;

  border-radius: 5px;
  background-color: white;
  font-family: Inter, serif;
}

.left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  width: 50%;
}

.checkbox {
  display: flex;
  flex-direction: row;
  gap: 5px;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 10px 0;
  max-width: 400px;
  cursor: pointer;
  color: var(--color-light-blue-gray);
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
  text-align: left;
  color: var(--color-dark-blue);
}

.subtitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 10px 0;
  max-width: 400px;
  color: var(--color-light-blue-gray);
}

.content {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.buttonbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
}

.button {
  padding: 10px;
  text-align: center;
  width: 200px;
  cursor: pointer;
  border: 1px solid var(--color-light-blue);
  border-radius: 5px;
  color: var(--color-dark-blue);
}

.active {
  color: var(--color-white);
  background-color: var(--color-gray-blue);
}

.close {
  position: absolute;
  top: 3%;
  left: 97%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .container{
    flex-direction: column;
    height: 100%;
    justify-content: start;
    padding: 0;
  }

  .left {
    padding: 10px 0;
    width: 100%;
    align-items: center;
  }

  .content {
    width: 100%;
    height: 90%;
  }

  .buttonbar {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    z-index: 2;
    justify-content: center;
  }

  .close {
    top: 2%;
    left: 92%;
    font-size: 2px;
    color: var(--color-dark-blue);
    font-weight: 700;
  }
}