.container {
  position: absolute;
  top: 15px;
  left: -160px;
  width: 170px;
  max-height: 220px;
  z-index: 100;

  background-color: var(--color-white-gray);
  border: 1px solid var(--color-orange);
  border-radius: 5px;
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-light-blue-gray);

  padding: 5px 20px;
  min-height: 40px;
  z-index: 155;
}

.row:hover {
  background-color: rgba(237, 120, 72, 0.3);
}