.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  position: fixed;
  transform: translate(-50%, -50%);

  top: 50%;
  left: 50%;
  z-index: 15;
  width: 70%;
  height: 80vh;
  padding: 20px;

  border-radius: 5px;
  border: 1px solid #D4D6E5;
  background-color: white;
}

.title {
  display: flex;
  justify-content: center;
  padding: 10px;

  font-size: 21px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--color-dark-blue);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  width: 125px;
  padding: 20px;
  cursor: pointer;

  color: var(--color-dark-blue);
  border-radius: 4px;
  border: 1px solid var(--color-light-blue);

  a {
    color: var(--color-dark-blue)
  }
}

.button:hover {
  color: var(--color-white);
  background-color: var(--color-dark-orange);

  a {
    color: var(--color-white)
  }
}

.viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 100%;
  height: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  height: 45px;
}

.background {
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #2a2929;
  position: fixed;
  transform: translate(-50%, -50%);

  top: 50%;
  left: 50%;
}