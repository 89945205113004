.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  min-width: 98%;
  padding: 10px 15px;
  margin-bottom: 20px;

  border-radius: 7px;
  background-color: #fff;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.reset {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 95px;
  height: 45px;
  cursor: pointer;

  border-radius: 7px;
  border: 1px solid var(--color-light-blue);
  background: var(--color-white-gray);
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--color-dark-blue);
  padding: 10px;
}

.button {
  padding: 10px;
  border: 1px var(--color-light-blue-gray) solid;
  text-align: center;
  border-radius: 5px;
  min-width: 100px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0;
    min-width: 0;
    justify-content: space-between;
  }
}