.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width:  100%;
  height: 50px;
  gap: 20px;
}

.containerClose {
  width: 60px;
}

.fadeIn {
  transition: opacity 0.5s;
  transition-delay: 1s;
  opacity: 1;
  display: block;
}

.fadeOut {
  transition: opacity 1s;
  opacity: 0;
  position: fixed;
  top: -9999%;
}

@media screen and (max-width: 768px){
  .container {
    transition: opacity 0.5s;
    transition-delay: 1s;
  }

  .containerClose {
    transition: opacity 1s;
    opacity: 0;
    position: fixed;
    top: -9999%;
  }
}