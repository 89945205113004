.container {
  margin-top: 10px;
  height: 80%;

  font-family: 'Inter', serif;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  text-transform: uppercase;
  text-align: left;

  color: var(--color-dark-blue);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border-radius: 7px 7px 0 0;
  background: var(--color-white);
}

.column {
  font-weight: 500;
  color: var(--color-dark-blue);

  padding: 10px 20px;
  width: 110px;
}

.id {
  width: 40px;
}

.table {
  height: 60%;
  min-width: 100%;
};

.footer{
  border-radius: 0 0 7px 7px;
  background: var(--color-white);
  margin-top: 2px;
}

.fullFooter {
  height: 8px;
  margin-top: 0;
}

.empty {
  margin-top: 3px;
  padding: 200px;
  box-sizing: border-box;
  background: var(--color-white);

  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 109.52%;
  text-align: center;
  color: var(--color-light-blue-gray);
}

.popup {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  min-height: 30vh;
  width: 90%;
  z-index: 100;
  padding: 10px;

  background: var(--color-white);
  border-radius: 3px;
}

.shadow {
  opacity: 0.5;
  background-color: var(--color-dark-gray);
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  min-width: 1000vh;
  min-height: 1000vh;
  z-index: 99;
}

.icon {
  display: flex;
  align-items: center;
}

.control {
  position: relative;
  width: 40px;
  cursor: pointer;
}

.guide {
  z-index: 100;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    border-radius: 5px;
    background-color: var(--color-white);
    padding: 10px 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .table {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin: 10px 0;
  }
}