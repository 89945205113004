.container {
  display: flex;
  justify-content: center;
  text-align: center;

  height: 30px;
}

.tab {
  width: 250px;
  color: var(--color-light-blue-gray);
  font-size: 14px;
  cursor: pointer;

  border-bottom: 2px solid var(--color-light-blue-gray);
}

.active {
  color: var(--color-orange);

  border-bottom: 2px solid var(--color-orange);
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .tab {
    width: 100%;
  }
}