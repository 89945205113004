.row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  color: var(--color-light-blue-gray);
  background: #fff;
  margin-top: 2px;
  height: 60px;
}


.items {
  font-weight: 400;

  padding: 10px 20px;
  width: 110px;
}

.id {
  width: 40px;
}

.control {
  position: relative;
  width: 40px;
  cursor: pointer;
}

.current {
  background-color: var(--color-dark-orange);
  color: white;

  border-radius: 5px;
}