.container {
  width: 700px;
  background: #fff;
  margin: 0 auto;
  padding: 10px 40px 20px 40px;
  border-radius: 7px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 50px;
  text-transform: uppercase;

  color: var(--color-dark-blue);
}

.button {
  width: 100px;
  border-radius: 7px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}

.confirm {
  background: var(--color-dark-orange);
  color: white;
}

.cancel {
  background-color: var(--color-white-gray);
  color: var(--color-dark-blue);
}



.block {
  margin-top: 10px;
  margin-bottom: 15px;
}

.input {
  margin-top: 20px;
}

.warning {
  height: 10px;
  font-size: 13px;
  color: red;
  margin: 10px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 80%;
    height: 85%;
    padding: 10px 40px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    font-size: 33px;
    font-weight: 640;
    text-align: center;
  }

  .footer {
    margin-top: 60px;
  }

  .block {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}