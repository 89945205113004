.container {
  background: var(--color-white);
  margin: 0 auto;
  padding: 70px;
  border-radius: 7px;
  font-family: "Inter", serif;
  font-style: normal;
}

.scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 55vh;

  padding: 0 10px;
}