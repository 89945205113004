.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  top: 12%;
  left: 0;

  width: 300px;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
}

.submenu {
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 1s;
}

.fadeOut {
  transition: opacity 1s;
  opacity: 0;
}

.test {
  color: red;
  padding: 0 10px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: var(--color-dark-blue);
}

.item:hover {
  color: var(--color-dark-orange);
}

.active {
  color: var(--color-dark-orange);

  svg path {
    stroke: var(--color-dark-orange);
  }

  svg g {
    fill: var(--color-dark-orange);
  }
}

.close {
  width: 50px;
}

.disappear {
  position: fixed;
  top: -999%;
  left: -999%;
}

@media screen and (max-width: 768px) {
  .fadeOut {
    transition: none;
  }

  .testClose {
    display: none;
  }
}